/** 
 * import : 
 * 
 * @author usayama
 * @version 0.1 (2021.01.26)
 * @version 0.2 (2021.02.17) // noizz_charts/App からコピー
 * @version 0.3 (2021.05.17) // react-router-dom から gatsby に変更
*/
import React from 'react'
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link } from 'gatsby'
// conf, action
// components
import Label from '@components/Typo/Label'
// helper
// style, img
// consts

/** 
 * const : 
 * 
 * @author usayama
 * @version 0.1 (2021.01.26)
 * @version 0.1 (2021.02.17) // noizz_charts/App からコピー
*/
const Button = props => {

  /** 
   * const : 
   * 
   * @author usayama
   * @version 0.3 (2021.02.12) // disabled 追加
   * @version 0.3 (2021.02.17) // noizz_charts/App からコピー
   * @version 0.4 (2021.02.17) // node 追加
   * @version 0.5 (2021.04.26) // buttonLabel 追加
   * @version 0.6 (2021.04.28) // color の判定処理追加
   */
  const { className,
          color,
          label,
          text,
          node,
          type,
          size,
          block,
          disabled,
          to, ...attrs } = props
  const classes = classNames(
    "btn",
    color && "btn-" + color,
    size && "btn-" + size,
    block && "btn-block",
    className,
  )
  const buttonLabel = label ? 'button.' + label : ''

  /** 
   * return : 
   * 
   * @author usayama
   * @version 0.3 (2021.02.12) // disabled 追加
   * @version 0.3 (2021.02.17) // noizz_charts/App からコピー
   * @version 0.4 (2021.02.17) // node 追加
   * @version 0.5 (2021.04.26) // buttonLabel に変更

   */
  return (
    to
    ? 
      <Link to={to} className={classes} disabled={disabled} {...attrs}>
        {<Label label={buttonLabel} />}{text}{node}
      </Link>
    : 
      <button type={type} className={classes} disabled={disabled} {...attrs}>
        {<Label label={buttonLabel} />}{text}{node}
      </button>
  )
}

/** 
 * propTypes
 * 
 * @author usayama
 * @version 0.3 (2021.02.12) // disabled 追加
 * @version 0.3 (2021.02.17) // noizz_charts/App からコピー
 * @version 0.4 (2021.02.17) // node 追加
*/
Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.node,
  node: PropTypes.node,
  type: PropTypes.string,
  size: PropTypes.string,
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  to: PropTypes.string,
}

/** 
 * defaultProps
 * 
 * @author usayama
 * @version 0.3 (2021.02.12) // disabled 追加
 * @version 0.3 (2021.02.17) // noizz_charts/App からコピー
 * @version 0.4 (2021.02.17) // node 追加
 * @version 0.5 (2021.04.28) // color のデフォルト値を削除
*/
Button.defaultProps = {
  className: "",
  color: "",
  label:null, 
  text: null,
  node: null,
  type: "button",
  size: null,
  block: false,
  disabled: false,
  to: null, 
}

/** 
 * export
 * 
 * @author usayama
 * @version 0.1 (2021.01.26)
*/
export default Button
