/** 
 * import : 
 * 
 * @version 0.1 (2021.06.12, 06.14 : usayama)
*/
import React from 'react'
import { Link } from 'gatsby'
import classNames from "classnames"
import PropTypes from "prop-types"
// conf, action
import { APP_ROUTE } from '@config/routers'
// components
import Label from '@components/Typo/Label'
// containers
// helper
import { calcPriceInTax } from '@lib/appHelpers'
// style, img, elm
// const
let numeral = require('numeral')

/** 
 * const : 
 * 
 * @version 0.1 (2021.06.12 : usayama)
 * @version 0.2 (2021.06.22 : usayama) // const に変更
*/
const ItemContainer = props => {

  /**
   * const
   *
   * @version 0.1 (2021.06.12 : usayama)
   */
  const { className, item } = props
  const classes = classNames( 
    "item", 
    "col-12 col-md-3", 
    className
  )

  /** 
   * useState : 
   * 
   * @version 0.1 (2021.06.14 : usayama)
   */
  const [ initPage, setInitPage ] = React.useState(false)

  /** 
   * componentDidMount : https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.06.12 : usayama)
  */
  React.useEffect(() => {
    if(!initPage) {

      setInitPage(true)
    }
  }, [initPage])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.06.12, 06.14 : usayama)
   * @version 0.2 (2021.06.22 : usayama) // デザイン調整
   * @version 0.3 (2021.06.28 : usayama) // is_sold_out 追加
   */
  return (
    <div className={classes}>
      <Link to={APP_ROUTE.shop + item.shop_url + '/' + item.url}>
        <figure>
          <img src={item.item_images[0]} alt="" />
          { item.stock <= 0 && <div className="is_sold_out"><Label label="label.is_sold_out" /></div> }
          {/*
          <div className="like">
            <button type="button" className="badge badge-circle active">
              <i className="bi bi-heart-fill"></i>
            </button>
          </div>
          */}
        </figure>
        <div className="shop">{item.shop_name}</div>
        <div className="name">{item.name}</div>
        <div className="price">
          <Label 
            label="label.item_price" 
            replaces={{'price' : numeral(calcPriceInTax(item.price_no_tax, item.tax)).format('0,0')}}
          />(<Label label="label.in_tax" />)
        </div>
        {/*
        <div className="stars">
          <i className="bi bi-star-fill"></i>
          <i className="bi bi-star-fill"></i>
          <i className="bi bi-star-fill"></i>
          <i className="bi bi-star-fill"></i>
          <i className="bi bi-star"></i>
        </div>
        */}
      </Link>
    </div>
  )
}

/** 
 * propTypes
 * 
 * @version 0.1 (2021.06.22 : usayama)
*/
ItemContainer.propTypes = {
  className: PropTypes.string,
}

/** 
 * defaultProps
 * 
 * @version 0.1 (2021.06.22 : usayama)
*/
ItemContainer.defaultProps = {
  className: "",
}

/** 
 * export
 * 
 * @version 0.1 (2021.06.22 : usayama)
*/
export default ItemContainer
