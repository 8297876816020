/**
 * import
 *
 * @version 0.1 (2021.06.14 : usayama)
 */
import React from 'react'
import { Router } from '@reach/router'
// conf, action
// components
// containers
import LayoutContainer from '@containers/LayoutContainer'
import MypointContainer from '@containers/MypointContainer'
import NavlinkContainer from '@containers/NavlinkContainer'
import ItemDetailContainer from '@containers/ItemDetailContainer'
import ShopDetailContainer from '@containers/ShopDetailContainer'
import ShopListContainer from '@containers/ShopListContainer'
// helper
// style, img, elm
// const

/**
 * const
 *
 * @version 0.1 (2021.06.14 : usayama)
 * @version 0.2 (2021.09.30 : usayama) // NavlinkContainer コメントアウト
 */
const Shops = () => (
	<LayoutContainer>
    {/* seo は ItemDetailContainer, ShopDetailContainer, ShopListContainer に入れる */}
		<MypointContainer />
		{/*<NavlinkContainer />*/}
    <Router basepath='/shops'>
      <ItemDetailContainer path='/:shop_url/:item_url' />
      <ShopDetailContainer path='/:shop_url' />
      <ShopListContainer path='/' />
    </Router>
	</LayoutContainer>
)

/**
 * export
 *
 * @version 0.1 (2021.06.14 : usayama)
 */
export default Shops