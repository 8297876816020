/** 
 * import : 
 * 
 * @version 0.1 (2021.06.14 : usayama)
*/
import React from 'react';
// import { HiHome } from "react-icons/hi";
// conf, action
// components
import Label from '@components/Typo/Label'
// containers
// helper
// style, img, elm
const iconImg = require("@assets/images/title-icon-map.png")
// const

/** 
 * const : 
 * 
 * @version 0.1 (2021.06.14 : usayama)
*/
export default function ShopMapContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.14 : usayama)
   */
  const { address } = props

  /** 
   * return : 
   * 
   * @version 0.1 (2021.06.14 : usayama)
   */
  return (
      <div className="map wrap mb-0">
      {
        address && <div>
            <div className="title title-h-line">
              <h3><Label label="title.shop_map" /></h3>
              <div className="h-line">
                <span></span>
                <img src={iconImg.default} alt="" />
                <span></span>
              </div>
            </div>
            <iframe title="google map" src={"https://maps.google.com/maps?output=embed&q=" + address + "&t=m&z=18"} width="100%"></iframe>
          </div>
      }
      </div>
  );
};
