/** 
 * import : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
 * @version 0.2 (2021.06.14 - 06.15 : usayama) // 実装
 * @version 0.3 (2021.06.28 : usayama) // Seo 追加
*/
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from 'gatsby'
import { Carousel } from 'react-responsive-carousel'
import { useTranslation } from 'react-i18next'
// conf, action
import { APP_ROUTE } from '@config/routers'
import itemActions from '@redux/item/actions'
import shopActions from '@redux/shop/actions'
import cartActions from '@redux/cart/actions'
import modalActions from '@redux/modal/actions'
// components
import Seo from '@components/Tools/Seo'
import Button from '@components/UIKit/Button'
import Checkmark from '@components/UIKit/Checkmark'
import Modal from '@components/UIKit/Modal'
import InputSelect from '@components/Form/InputSelect'
import Label from '@components/Typo/Label'
// containers
import ItemsContainer from '@containers/ItemsContainer'
// helper
import { calcPriceInTax } from '@lib/appHelpers'
import { replaceBr } from '@lib/helpers'
// style, img, elm
import 'react-responsive-carousel/lib/styles/carousel.min.css'
// const
const { initGetItem, getItem } = itemActions
const { initGetShop, getShop } = shopActions
const { setCartItem } = cartActions
const { initModal, openModal } = modalActions
let numeral = require('numeral')

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
*/
export default function ItemDetailContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.05.13 : usayama)
   * @version 0.2 (2021.06.28 : usayama) // useTranslation 追加
   */
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { item } = useSelector(state => state.Item)
  const { shop } = useSelector(state => state.Shop)
  const { shop_url, item_url } = props

  /** 
   * state : 
   * 
   * @version 0.1 (2021.05.13 : usayama)
  */
  const [ initPage, setInitPage ] = React.useState(false)
  const [ selectedNum, setSelectedNum ] = React.useState(1)

  /** 
   * useEffect
   * 
   * @version 0.1 (2021.06.14 : usayama)
   */
  React.useEffect(() => {
    dispatch(initGetItem())
    dispatch(initGetShop())
    dispatch(initModal())
    shop_url && item_url && dispatch(getItem(shop_url, item_url))
    shop_url && dispatch(getShop(shop_url))
  }, [item_url, shop_url, dispatch])


  /** 
   * componentDidMount : https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.06.14 : usayama)
  */
  React.useEffect(() => {
    if(!initPage) {
      setInitPage(true)
    }
  }, [initPage])

  /** 
   * event : 
   * 
   * @version 0.1 (2021.06.14 : usayama)
   * @version 0.2 (2021.06.15 : usayama) // handleOnChangeNum 追加, handleOnAddCart に selectedNum 追加 
  */
  const handleOnChangeNum = (e) => setSelectedNum(e.target.value)
  const handleOnAddCart = (e) => {
    dispatch(setCartItem({...e, 'selectedNum' : selectedNum}))
    dispatch(openModal())
  }
  const handleOnBuyNow = (e) => {
    dispatch(setCartItem({...e, 'selectedNum' : selectedNum}))
    navigate(APP_ROUTE.cart)
  }

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.13 : usayama)
   * @version 0.2 (2021.06.14 : usayama) // 実装
   * @version 0.3 (2021.06.28 : usayama) // Seo 追加
   * @version 0.4 (2021.06.29 : usayama) // 在庫 0 対応
   */
  return (
  <div className="item-detail wrap">
    { item ? <Seo title={item.name} /> : <Seo title={t('title.item')} /> }
    {/*<Checkmark />*/}
    <Modal bgColor="rgb(255,255,255,0.9)">
      <div>
        { item.item_images && item.item_images.length > 0 && <div><img src={item.item_images[0]} alt="" style={{'width': 210}} /></div> }
        <div className="mt-3"><Checkmark /></div>
        <div className="h3 text-center text-success mt-3"><strong><Label label="text.add_cart_item" /></strong></div>
      </div>
    </Modal>
    <div className="container">
    {
      item && 
      <div className="row">
        <div className="col-12 col-md-5">
          <Carousel
            showArrows={true}
            showStatus={true}
            showIndicators={true}
            infiniteLoop={true}
            showThumbs={true}
            useKeyboardArrows={true}
            autoPlay={true}
            stopOnHover={true}
            swipeable={true}
            dynamicHeight={true}
            emulateTouch={true}
            // autoFocus={false}
          >
          { item.item_images && item.item_images.length > 0 && item.item_images.map((image, key) => <div key={key}><img src={image} alt="" /></div> ) }
          </Carousel>
        </div>
        <div className="col-12 col-md-7">
          <div className="item">
            <div className="shop">{item.shop_name}</div>
            <div className="name">{item.name}</div>
            <div className="price">
              <Label 
                label="label.item_price" 
                replaces={{'price' : numeral(calcPriceInTax(item.price_no_tax, item.tax)).format('0,0')}}
              />(<Label label="label.in_tax" />)
            </div>
            {/*
            <div className="stars">
              <i className="bi bi-star-fill"></i>
              <i className="bi bi-star-fill"></i>
              <i className="bi bi-star-fill"></i>
              <i className="bi bi-star-fill"></i>
              <i className="bi bi-star"></i>
            </div>
            */}
            <div className="info mt-2">
              <Label className="font-weight-bold" label="label.shipping_fee" /> : <Label label="label.item.shipping_fee" /> {/* 配送料は無料固定 */}
            </div>
            { item.stock <= 0 && <div className="color-red fw-bold"><Label label="label.is_sold_out" /></div> }
            { item.stock > 0 && <InputSelect 
                name="order_num"
                className="mt-3 form-select-num" 
                label="order_num" 
                defaultValue={selectedNum}
                options={item.order_num_options}
                onChange={handleOnChangeNum}
              /> 
            }
            { item.stock > 0 && 
              <div className="row mt-3 buttons">
                <div className="col-12 col-md-6">
                  <Button color="success" className="w-100 mb-3" label="add_cart" onClick={() => handleOnAddCart(item)} />
                </div>
                <div className="col-12 col-md-6">
                  <Button color="outline-success" className="w-100 mb-3" label="buy_now" onClick={() => handleOnBuyNow(item)} />
                </div>
              </div>
            }
            { item.text && <div className="mt-3 alert text" dangerouslySetInnerHTML={{ __html: replaceBr(item.text) }} /> }
            <table className="table">
              <tbody>
                {
                  item.table_keys && item.table_keys.map((row, i) => {
                    if(item.table_keys[i]) {
                      return <tr key={i}>
                          <th className="text-nowrap" width="25%">{item.table_keys[i]}</th>
                          <td>{item.table_values[i]}</td>
                      </tr>
                    } else {
                      return <></>
                    }
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    }
    { shop && shop.items && <ItemsContainer items={shop.items} title="title.shop_items" titleReplaces={{'shop_name' : shop.name}} /> }
    </div>
  </div>
  )
}
