/** 
 * import : 
 * 
 * @version 0.1 (2021.06.14 : usayama)
*/
import React from 'react'
// conf, action
// components
import Label from '@components/Typo/Label'
// containers
import ItemContainer from '@containers/ItemContainer'
// helper
// style, img, elm
const iconImg = require("@assets/images/title-icon-items.png")
// const

/** 
 * const : 
 * 
 * @version 0.1 (2021.06.14 : usayama)
*/
export default function ItemsContainer(props) {

  /**
   * const
   *
   * @version 0.1 (2021.06.14 : usayama)
   */
  const { items, title, titleReplaces } = props

  /** 
   * return : 
   * 
   * @version 0.1 (2021.06.14 : usayama)
   * @version 0.2 (2021.06.27 : usayama) // デザイン調整
   */
  return (
  <div className="shop-items wrap" id="items">
    <div className="title title-h-line">
      <h3><Label label={title} replaces={titleReplaces && titleReplaces} /></h3>
      <div className="h-line">
        <span></span>
        <img src={iconImg.default} alt="" />
        <span></span>
      </div>
    </div>
    <div className="items">
      <div className="row">
      { items && items.map((item, i) => <ItemContainer item={item} key={i} /> )}
      </div>
    </div>
  </div>
  )
}
