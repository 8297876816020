/** 
 * import : 
 * 
 * @version 0.1 (2021.06.14 : usayama)
*/
import React from 'react'
import { Link } from 'gatsby'
// conf, action
import { APP_ROUTE } from '@config/routers'
// components
// containers
// helper
// style, img, elm
// const

/** 
 * const : 
 * 
 * @version 0.1 (2021.06.14 : usayama)
*/
export default function ShopContainer(props) {

  /**
   * const
   *
   * @version 0.1 (2021.06.14 : usayama)
   */
  const { shop } = props

  /** 
   * useState : 
   * 
   * @version 0.1 (2021.06.14 : usayama)
   */
  const [ initPage, setInitPage ] = React.useState(false)

  /** 
   * componentDidMount : https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.06.14 : usayama)
  */
  React.useEffect(() => {
    if(!initPage) {
      setInitPage(true)
    }
  }, [initPage])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.06.14 : usayama)
   * @version 0.2 (2021.06.22 : usayama) // デザイン調整
   */
  return (
    <div className="shop col-12 col-md-6">
      <Link to={APP_ROUTE.shop + shop.url}>
        <div className="shop">
          <figure>
            <img src={shop.face_image} alt="" />
          </figure>
          <div>
            <h3>{shop.name}</h3>
            <p>{shop.address}</p>
          </div>
        </div>
      </Link>
    </div>
  )
}
