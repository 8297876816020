/** 
 * import : 
 * 
 * @version 0.1 (2020.06.14 : usayama)
*/
import React from 'react'
import PropTypes from 'prop-types'
// import classNames from 'classnames'
// import styled from 'styled-components'
// conf, action
// components
// containers
// style, img
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2020.06.14 : usayama)
*/
const Checkmark = props => {

  /** 
   * return : 
   * 
   * @version 0.1 (2020.06.14 : usayama)
   */
  return (
    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" stroke="#35aa47">
      <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
      <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
    </svg>
  )

}

/** 
 * propTypes
 * 
 * @version 0.1 (2020.06.14 : usayama)
*/
Checkmark.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  bgColor: PropTypes.string,
}

/** 
 * defaultProps
 * 
 * @version 0.1 (2020.06.14 : usayama)
*/
Checkmark.defaultProps = {
  isOpen: false,
  children: null,
  bgColor: 'rgba(0,0,0,0.8)',
}

/** 
 * export
 * 
 * @version 0.1 (2020.06.14 : usayama)
*/
export default Checkmark
