/** 
 * import : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
 * @version 0.2 (2021.06.28 : usayama) // Seo 追加
*/
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
// conf, action
import shopActions from '@redux/shop/actions'
// import { APP_ROUTE } from '../config/routers'
// components
import Seo from '@components/Tools/Seo'
import Label from '@components/Typo/Label'
// containers
import ShopContainer from '@containers/ShopContainer'
// helper
// style, img, elm
const iconImg = require("@assets/images/title-icon-shops.png")
// const
const { initGetShops, getShops } = shopActions

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
*/
export default function ShopListContainer(props) {

  /**
   * const
   *
   * @version 0.2 (2021.06.14 : usayama)
   * @version 0.2 (2021.06.28 : usayama) // useTranslation 追加
   */
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { shops } = useSelector(state => state.Shop)

  /** 
   * state : 
   * 
   * @version 0.1 (2021.05.13 : usayama)
  */
  const [initPage, setInitPage] = React.useState(false)

  /** 
   * componentDidMount
   * https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.05.13 : usayama)
   * @version 0.2 (2021.06.14 : usayama)
   */
  React.useEffect(() => {
    if(!initPage) {
      dispatch(initGetShops())
      dispatch(getShops())
      setInitPage(true)
    }
  }, [initPage, dispatch])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.13 : usayama)
   * @version 0.2 (2021.06.14 : usayama)
   * @version 0.3 (2021.06.22 : usayama) // デザイン調整
   * @version 0.4 (2021.06.28 : usayama) // Seo 追加
   * @version 0.5 (2021.07.06 : usayama) // Seo コメントアウト
   * @version 0.6 (2021.07.09 : usayama) // icon コメントアウト
   */
  return (
  <div className="shops wrap">
    {/*<Seo title={t('title.shops')} />*/}
    <div className="container">
      <div className="title title-h-line">
        <h3><Label label="title.shops" /></h3>
        <div className="h-line">
          <span></span>
          {/*<img src={iconImg.default} alt="" />*/}
          <span></span>
        </div>
      </div>
      <div className="shops row">
      { shops && shops.map((shop, i) => <ShopContainer shop={shop} key={i} /> )}
      </div>
    </div>
  </div>
  )
}
