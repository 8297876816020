/** 
 * import : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
 * @version 0.2 (2021.06.14 : usayama) // 実装
 * @version 0.3 (2021.06.28 : usayama) // Seo 追加
*/
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
// conf, action
import shopActions from '@redux/shop/actions'
// components
import Seo from '@components/Tools/Seo'
// containers
import ItemsContainer from '@containers/ItemsContainer'
import ShopMapContainer from '@containers/ShopMapContainer'
// helper
import { replaceBr } from '@lib/helpers'
// style, img, elm
const iconImg = require("@assets/images/title-icon-shops.png")
// const
const { initGetShop, getShop } = shopActions

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
 * @version 0.2 (2021.06.14 : usayama) // 実装
*/
export default function ShopDetailContainer(props) {

  /**
   * const
   *
   * @version 0.1 (2021.06.14 : usayama)
   * @version 0.2 (2021.06.28 : usayama) // useTranslation 追加
   */
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { shop } = useSelector(state => state.Shop)
  const { shop_url } = props

  /** 
   * useEffect
   * 
   * @version 0.1 (2021.05.13 : usayama)
   * @version 0.2 (2021.06.14 : usayama) // 実装
   */
  React.useEffect(() => {
    dispatch(initGetShop())
    if(shop_url) {
      dispatch(getShop(shop_url))
    }
  }, [shop_url, dispatch])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.13 : usayama)
   * @version 0.2 (2021.06.14 : usayama) // 実装
   * @version 0.3 (2021.06.28 : usayama) // Seo 追加
   * @version 0.4 (2021.06.30 : usayama) // className= に修正
   * @version 0.5 (2021.08.13 : usayama) // main_image null 時の対応, Seo title の判定処理修正
   */
  return (
    <div className="">
      { (shop && shop.name) ? <Seo title={shop.name} /> : <Seo title={t('title.shop')} /> }
      {
        shop && 
        <div>
          <div className="shop-about shop-about-title">
            {
              shop.main_image && 
              <div 
                className="shop-main-img" 
                style={{'background-image': `url(${shop.main_image})`, 'background-image-repeat' :'no-repeat', 'background-size' :'cover'}}
              >
              </div>
            }
            <div className="container">
              <div className="title text-center">
                <h1>{shop.name}</h1>
                <p>{shop.address}</p>
              </div>
            </div>
          </div>
          {
            shop.sections && shop.sections.map((section, key) => {
              if(section.title || section.text) {
                return <div className="shop-about wrap" key={key}>
                        <div className="container">
                          {
                            section.title && <div className="title title-h-line">
                                                <h3>{section.title}</h3>
                                                <div className="h-line">
                                                  <span></span>
                                                  <img src={iconImg.default} alt="" />
                                                  <span></span>
                                                </div>
                                              </div>
                          }
                          { section.text && <div className="text text-center mb-4" dangerouslySetInnerHTML={{ __html: replaceBr(section.text) }} /> }
                          <div className="row">
                          {
                            section.images.map((image, i) => {
                              if(image.img) {
                                return <div className="ool-12 col-md-6" key={key + '-' + i}>
                                          <figure>
                                            <img src={image.img} className="img-fluid" alt="" />
                                          </figure>
                                          <h3 className="text-center">{image.title}</h3>
                                          <div className="text"><p>{image.text}</p></div>
                                        </div>
                              } else {
                                return <></>
                              }
                            })
                          }
                          </div>
                        </div>
                      </div>
              } else {
                return <></>
              }
            })
          }
        </div>
      }
      <ItemsContainer items={shop.items} title="title.shop_items" titleReplaces={{'shop_name' : shop.name}} />
      <ShopMapContainer address={shop.address} />
    </div>
  )
}
